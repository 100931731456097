@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Bitter', serif;
}

html,
body {
  height: 100%;
  width: 100%;
}

.App {
  height: 100vh;
  width: 100%;
}

body {
  background: linear-gradient(75deg, #001f54, #034078, #1282a2) fixed;
}

li {
  list-style: none;
  font-size: 18px;
}

/* Navbar */
.main-nav {
  display: flex;
  flex-direction: column;
}

.nav {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.nav a {
  color: white;
  margin: 10px;
  padding: 5px;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}

.links {
  text-decoration: none;
  color: white;
}

.home,
.about,
.portfolio,
.change-theme {
  font-size: 2rem;
}

.dev-name {
  font-size: 4rem;
}

.error {
  color: white;
  text-align: center;
  margin-top: 20px;
}

/* Hover effect by https://codepen.io/t_afif/pen/KKNYoMo */
.hover-effect {
  background:
    linear-gradient(#3FB8AF 0 0) left /var(--d, 0%) 100% no-repeat;
  transition: 0.15s;
}

.hover-effect:hover {
  --d: 100%;
  color: #fff;
}

/* Main (Home) */
.wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  margin-top: 20px;
}

.profile {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.circle-profile {
  border-radius: 50%;
  height: 300px;
  width: 300px;
  border: #3FB8AF solid 10px;
}

.circle-profile img {
  justify-content: center;
}

.profile-text {
  display: flex;
  flex: 2;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: white;
  padding-left: 20px;
}

.profile-text h1 {
  font-size: 4rem;
}

.profile-text h2 {
  font-size: 3rem;
}

.profile-text ul li:first-of-type {
  margin-top: 20px;
}

.profile-text ul li {
  list-style: none;
  font-size: 1.5rem;
  margin-top: 10px;
}

.socials {
  margin-top: 2rem;
}

.social-icons {
  margin-right: 10px;
}

.social-bkg:hover {
  background-color: #3FB8AF;
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.email a {
  color: white;
  text-decoration: none;
}

.footer {
  /* position: fixed;
  left: 0;
  bottom: 50px;
  right: 0; */
  text-align: center;
  color: #DDF2EB;
  margin-top: auto;
}

footer a {
  color: #DDF2EB;
  text-decoration: none;
}

footer a:hover {
  color: #3FB8AF;
}

/* animation for hand emoji https://jarv.is/notes/css-waving-hand-emoji/ */
.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: 2;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }

  10% {
    transform: rotate(14.0deg)
  }

  20% {
    transform: rotate(-8.0deg)
  }

  30% {
    transform: rotate(14.0deg)
  }

  40% {
    transform: rotate(-4.0deg)
  }

  50% {
    transform: rotate(10.0deg)
  }

  60% {
    transform: rotate(0.0deg)
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0.0deg)
  }
}

/* About page */

.about-container {
  align-items: center;
}

#about-text-box {
  max-width: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about {
  text-align: center;
  color: rgb(255, 255, 255);
}

.pages-left-container {
  display: flex;
  flex: 2;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: white;
  padding: 0px 10px;
}

.pages-left-container ul li {
  padding-bottom: 10px;
}

.pages-left-container li {
  padding-left: 1rem;
  text-indent: -0.7rem;
}

.pages-left-container li:nth-child(3n+1)::before {
  content: '👫 '
}

.pages-left-container li:nth-child(3n+2)::before {
  content: '🧑🧑‍🦱 ';
  /* content: '&#128107;&#x1F9D1;🧑‍🦱 '; */
}

.pages-left-container li:nth-child(3n)::before {
  content: '🐶  ';
  /* content: '&#128054; ' */
}

.main-container h2 {
  color: #3FB8AF;
}

.about-p {
  line-height: 1.5rem;
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.pages-left-container ul li {
  font-size: 1.3rem;
}

.right {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.bottom {
  display: flex;
  flex-direction: column;
}

.margin-bottom {
  margin-bottom: 10px;
}

.margin-top {
  margin-top: 10px;
}

/* Portfolio Page */
.portfolio-container,
.about-container {
  padding: 50px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.my-projects-title,
.about-title {
  text-align: center;
  color: white;
}

.portfolio-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 16rem));
  gap: 2rem;
  justify-content: center;
  margin-top: 20px;
}

.card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 1px 8px #001f54;
  cursor: pointer;
  transition: transform 50ms ease-in;
}

.card img {
  height: 12rem;
  width: 100%;
  object-fit: cover;
}

.card:hover {
  transform: scale(1.02);
}

.card-body img {
  height: 12rem;
  width: 100%;
  object-fit: cover;
}

.card-title {
  padding: 1rem;
  color: white;
  text-align: center;
  font-weight: bold;
}

a {
  text-decoration: none;
}

.card-description {
  padding: 0 1rem;
  color: white;
  line-height: 1.5rem;
}

.card-btn {
  padding: 1rem;
  font-family: inherit;
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem;
  border: none;
  color: #001f54;
  border-radius: 3px;
  cursor: pointer;
}

.card-btn:hover {
  background: #3FB8AF;
  color: white;
}

.project-view-text {
  background-color: white;
  text-decoration: none;
  text-align: center;
}

/* Media Queries */

@media (max-width: 768px) {

  /* navbar */
  .nav {
    gap: 0;
  }

  .nav .dev-name,
  .nav .change-theme {
    display: none;
  }

  /* Home */
  .main-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .circle-profile {
    height: 200px;
    width: 200px;
    border: #3FB8AF solid 5px;
  }

  .circle-profile img {
    justify-content: center;
  }

  .profile-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    padding-left: 0px;
    padding: 5px;
  }

  .profile-text h1 {
    font-size: 2rem;
  }

  .profile-text h2 {
    font-size: 1.5rem;
  }

  .pages-left-container ul li {
    font-size: 1rem;
  }

  .social-icons:hover {
    background-color: none;
  }

  /* About Me */
  .about-container {
    margin-top: 5px;
    padding: 2px;
  }

  /* Smaller text size */
  .about-p,
  .about-p ul li,
  .profile-text ul li {
    font-size: 1rem;
  }

  /* Remove profile photo */
  .right {
    display: none;
  }

  /* Footer */
  .footer {
    padding: 0px;
    font-size: .75rem;
    position: relative;
    left: 0;
    bottom: 20px;
    right: 0;
  }
}